.cell-center {
  text-align: center;
}
.cell-center > * {
  margin: 0 auto;
}

.cell-right {
  text-align: right;
}
.cell-right > * {
  margin: 0 0 0 auto;
}
/* .ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
} */

.ql-picker.ql-font .ql-picker-label[data-value='Roboto']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Roboto']::before {
  font-family: 'Roboto', cursive;
  content: 'Roboto' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Raleway']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Raleway']::before {
  font-family: 'Raleway', cursive;
  content: 'Raleway' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Montserrat']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Montserrat']::before {
  font-family: 'Montserrat', cursive;
  content: 'Montserrat' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Lato']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Lato']::before {
  font-family: 'Lato', cursive;
  content: 'Lato' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Rubik']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Rubik']::before {
  font-family: 'Rubik', cursive;
  content: 'Rubik' !important;
}
.ql-picker.ql-font .ql-picker-label[data-value='Zen-Kaku-Gothic-New']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Zen-Kaku-Gothic-New']::before {
  font-family: 'Zen-Kaku-Gothic-New', cursive;
  content: 'Zen-Kaku-Gothic-New' !important;
}
/* Set content font-families */
.ql-font-Roboto {
  font-family: 'Roboto';
}
.ql-font-Raleway {
  font-family: 'Raleway';
}
.ql-font-Montserrat {
  font-family: 'Montserrat';
}
.ql-font-Lato {
  font-family: 'Lato';
}
.ql-font-Rubik {
  font-family: 'Rubik';
}
.ql-font-Zen-Kaku-Gothic-New {
  font-family: 'Zen Kaku Gothic New';
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
